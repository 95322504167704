import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { Router } from 'workbox-routing';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <App />
);

serviceWorkerRegistration.register();
